



























































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import {
  ChipsList,
  MediaSectionList,
  PeriodSectionList,
} from "@/components/lists";
import {
  CertificateModel,
  MediaModel,
  PedigreeCompanyModel,
  SchoolModel,
  SkillModel,
} from "@/models/common";
import { CandidatePageModel } from "@/models/Page";
import { VacancyTitleModel } from "@/models/Vacancy";
import { CandidateService, PageService } from "@/services/Public";
import { IUpdateHeaderFooterData } from "@/store/modules/headerFooterInfo";
import TextViewer from "@/components/common/TextViewer.vue";
import PageContainer from "@/views/shared/PageContainer.vue";
import CandidateStickyMenu from "./CandidateStickyMenu.vue";
import CandidateProfileHeader from "./CandidateProfileHeader.vue";
import CandidateDetailsCard from "./CandidateDetailsCard.vue";
import SimilarCandidatesList from "./SimilarCandidatesList.vue";
import InterviewDialog from "./dialogs/InterviewDialog.vue";
import InviteDialog from "./dialogs/InviteDialog.vue";
import SaveDialog from "./dialogs/SaveDialog.vue";
import ReportConfirmationDialog from "./dialogs/ReportConfirmationDialog.vue";
import PassCandidateDialog from "./dialogs/PassCandidateDialog.vue";
import ScoopDialog from "./dialogs/ScoopDialog.vue";
import MessageDialog from "./dialogs/MessageDialog.vue";
import MessageDialogModel from "./models/MessageDialogModel";
import ECandidatePageMenuAction from "./models/enums/ECandidatePageMenuAction";

const HeaderFooterInfo = namespace("headerFooterInfo");

@Component({
  name: "CandidateProfile",
  components: {
    PageContainer,
    CandidateProfileHeader,
    CandidateDetailsCard,
    TextViewer,
    CandidateStickyMenu,
    ChipsList,
    PeriodSectionList,
    MediaSectionList,
    SimilarCandidatesList,
    InterviewDialog,
    InviteDialog,
    SaveDialog,
    ScoopDialog,
    PassCandidateDialog,
    ReportConfirmationDialog,
    MessageDialog,
  },
})
export default class extends Vue {
  @HeaderFooterInfo.Action("updateData")
  updateHeaderFooterInfo!: IUpdateHeaderFooterData;

  private profile: CandidatePageModel | null = null;

  private messageDialogConfig: MessageDialogModel | null = null;

  private showReportDialog = false;

  private showScoopDialog = false;

  private showPassDialog = false;

  private showSaveDialog = false;

  private showInviteDialog = false;

  private showInterviewDialog = false;

  private loading = false;

  private loadingReport = false;

  async mounted() {
    try {
      this.loading = true;
      this.updateHeaderFooterInfo();

      await this.getProfile();
    } catch (e) {
      console.log("Candidate profile fetch error: ", e);
    } finally {
      this.loading = false;
    }
  }

  async getProfile() {
    const candidateUrl = this.$router.currentRoute.fullPath;
    this.profile = await PageService.getProfile(candidateUrl);
  }

  isAuthenticated(isEmployee = true) {
    return isEmployee
      ? this.profile?.isEmployer && this.profile.isAuthenticated
      : this.profile?.isAuthenticated;
  }

  async onProfileMenuItemSelected(value: any) {
    switch (value.action) {
      case ECandidatePageMenuAction.Pass:
        this.showPassDialog = true;
        break;
      case ECandidatePageMenuAction.Save:
        await this.saveToFavorites();
        break;
      case ECandidatePageMenuAction.SaveTo:
        this.showSaveDialog = true;
        break;
      case ECandidatePageMenuAction.Scoop:
        this.showScoopDialog = true;
        break;
      case ECandidatePageMenuAction.Report:
        this.showReportDialog = true;
        break;
      case ECandidatePageMenuAction.Forward:
        this.openNewMessageDialog(true);
        break;
      case ECandidatePageMenuAction.Notes:
        this.openNotesPage();
        break;
      default:
        break;
    }
  }

  openNewMessageDialog(forward = false) {
    if (!this.profile?.candidate) return;

    this.messageDialogConfig = {
      show: true,
      recipientFirstName: this.profile.candidate?.information.firstName,
      recipientLastName: this.profile.candidate?.information.lastName,
      recipientId: this.profile.candidateId,
      forwardLink: forward ? "http://test.com" : undefined,
    };
  }

  openNotesPage() {
    const url = `${process.env.VUE_APP_MAIN_SITE_URL}/Iam/EmployerDashboard/CandidateNotes?candidateId=${this.profile?.candidateId}&isInsideScoop=false&jobId=00000000-0000-0000-0000-000000000000&link=${window.location.href}`;
    window.open(url, "_blank");
  }

  get skills() {
    const items = this.profile?.candidate?.pedigree?.skills;
    if (!items) return [];
    return items.map((x: SkillModel) => ({ text: x.title }));
  }

  get experience() {
    const items = this.profile?.candidate?.pedigree?.companies;
    if (!items) return [];
    return items.map((x: PedigreeCompanyModel) => ({
      title: x.title,
      subtitle: x.companyName,
      period: x.isCurrentJob ? "Current" : "",
    }));
  }

  get education() {
    const items = this.profile?.candidate?.pedigree?.schools;
    if (!items) return [];
    return items.map((x: SchoolModel) => ({
      title: `${x.fieldOfStudy}/${x.degree}`,
      subtitle: x.schoolName,
      period: x.isCurrentSchool ? "Current" : "",
    }));
  }

  get certificates() {
    const items = this.profile?.candidate?.pedigree?.certifications;
    if (!items) return [];
    return items.map((x: CertificateModel) => ({
      title: x.certificateName,
      subtitle: x.schoolName,
    }));
  }

  get media() {
    const items = this.profile?.candidate?.media;
    if (!items) return [];
    return items.map((x: MediaModel) => ({
      title: x.title,
      src: x.fileUrl,
      previewSrc: x.previewUrl,
      type: x.fileType,
      extension: x.extension,
      isCv: x.isCv,
    }));
  }

  get appliedOpportunities() {
    const items = this.profile?.candidate?.appliedOpportunities;
    if (!items) return [];
    return items.map((x: VacancyTitleModel) => ({
      id: x.id,
      title: x.title,
    }));
  }

  get activeCompanyOpportunities() {
    const items = this.profile?.candidate?.activeCompanyOpportunities;
    if (!items) return [];

    const appliedIds = (
      this.profile?.candidate?.appliedOpportunities || []
    ).map((opportunity) => opportunity.id);

    return items
      .filter((opportunity) => !appliedIds.includes(opportunity.id))
      .map((x) => ({
        id: x.id,
        title: x.title,
      }));
  }

  async saveToFavorites() {
    try {
      const candidateId = this.profile?.candidateId;
      if (!candidateId) return;

      await CandidateService.save(candidateId, []);
      this.$toast.success("Candidate successfully saved to Favorites.");
    } catch (e) {
      this.$toast.error("Saving error.");
      console.log("Save candidate error: ", e);
    }
  }

  async callToActionReport() {
    try {
      const candidateId = this.profile?.candidateId;
      this.loadingReport = true;
      if (!candidateId) {
        return;
      }
      await CandidateService.report(candidateId);
      this.showReportDialog = false;
    } finally {
      this.loadingReport = false;
    }
  }
}
