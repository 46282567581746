export function toIsoLocalTime(value: Date) {
  // https://stackoverflow.com/a/72809422
  if (value instanceof Date === false) value = new Date();
  const off = value.getTimezoneOffset() * -1;
  const del = value.getMilliseconds() ? "Z" : "."; // have milliseconds ?
  value = new Date(value.getTime() + off * 60000); // add or subtract time zone
  return (
    value.toISOString().split(del)[0] +
    (off < 0 ? "-" : "+") +
    ("0" + Math.abs(Math.floor(off / 60))).substr(-2) +
    ":" +
    ("0" + Math.abs(off % 60)).substr(-2)
  );
}
